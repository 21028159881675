import { SVG_TYPES, ROUTES } from 'shared/consts'

const MENU_ITEMS = [
  {
    label: 'Strona główna',
    path: ROUTES.HOME_PAGE,
    icon: SVG_TYPES.HOME,
  },
  {
    label: 'Użytkownicy',
    path: ROUTES.USERS,
    icon: SVG_TYPES.USERS,
  },
  {
    label: 'Mówcy',
    path: ROUTES.PREACHERS,
    icon: SVG_TYPES.PREACHER,
  },
  {
    label: 'Miejsca',
    path: ROUTES.LOCATIONS,
    icon: SVG_TYPES.LOCATION,
  },
  {
    label: 'Nagrania',
    path: ROUTES.SESSIONS,
    icon: SVG_TYPES.RECORDING,
  },
  {
    label: 'Dodaj nagranie',
    path: ROUTES.MY_SESSIONS,
    icon: SVG_TYPES.PLUS,
  },
  {
    label: 'Historia odtwarzania',
    path: ROUTES.HISTORY,
    icon: SVG_TYPES.RECORDING,
  },
]

export default MENU_ITEMS
