const VIEW_MANAGEMENT = {
  SHOW_PRELOADER: 'SHOW_PRELOADER',
  HIDE_PRELOADER: 'HIDE_PRELOADER',
  SHOW_PROGRESS_BAR: 'SHOW_PROGRESS_BAR',
  HIDE_PROGRESS_BAR: 'HIDE_PROGRESS_BAR',
  SET_SELECTED_ROW: 'SET_SELECTED_ROW',
  UPDATE_SELECTED_ROW: 'UPDATE_SELECTED_ROW',
  SET_PROGRESS_BAR_DATA: 'SET_PROGRESS_BAR_DATA',
}

export default VIEW_MANAGEMENT
