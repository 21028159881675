import React, { useState, useCallback } from 'react'
import { Hamburger, Navigation } from './components'

export const MobileNavigation = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleOnClick = useCallback(() => setIsExpanded(prevState => !prevState), [])

  return (
    <>
      <Hamburger isExpanded={isExpanded} handleOnClick={handleOnClick} />
      {isExpanded && <Navigation handleOnClick={handleOnClick} />}
    </>
  )
}
