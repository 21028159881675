const PREACHER = {
  GET_PREACHERS_FAILURE: 'GET_PREACHERS_FAILURE',
  GET_PREACHERS_SUCCESS: 'GET_PREACHERS_SUCCESS',
  GET_PREACHERS_OPTIONS_FAILURE: 'GET_PREACHERS_OPTIONS_FAILURE',
  GET_PREACHERS_OPTIONS_SUCCESS: 'GET_PREACHERS_OPTIONS_SUCCESS',
  SET_SELECTED_PREACHER_SUCCESS: 'SET_SELECTED_PREACHER_SUCCESS',
  ADD_PREACHER_SUCCESS: 'ADD_PREACHER_SUCCESS',
  ADD_PREACHER_FAILURE: 'ADD_PREACHER_FAILURE',
  EDIT_PREACHER_SUCCESS: 'EDIT_PREACHER_SUCCESS',
  EDIT_PREACHER_FAILURE: 'EDIT_PREACHER_FAILURE',
  DELETE_PREACHER_SUCCESS: 'DELETE_PREACHER_SUCCESS',
  DELETE_PREACHER_FAILURE: 'DELETE_PREACHER_FAILURE',
}

export default PREACHER
