import React from 'react'
import { Status } from 'shared/components'
import { oneOfType, number, bool } from 'prop-types'

function TableStatus({ isVerified }) {
  return (
    <div className="table__status">
      <Status isVerified={isVerified} />
    </div>
  )
}

TableStatus.propTypes = {
  isVerified: oneOfType([bool, number]).isRequired,
}

export default TableStatus
