const CONVERSION_STATUSES = {
  PENDING: 'pending',
  DOWNLOADING: 'downloading',
  DOWNLOADING_FAILED: 'downloading_failed',
  PROCESSING: 'processing',
  PROCESSING_FAILED: 'processing_failed',
  DUPLICATE: 'duplicate',
  CONVERTING: 'converting',
  CONVERSION_FAILED: 'conversion_failed',
  CONVERSION_FINISHED: 'conversion_finished',
  CONVERSION_PROCESSING: 'conversion_processing',
  CONVERSION_PROCESSING_FAILED: 'conversion_processing_failed',
  UPLOADING: 'uploading',
  UPLOADING_FAILED: 'uploading_failed',
  FINISHED: 'finished',
}

export default CONVERSION_STATUSES
