import { AUTH } from 'store/types'
import { authConnector } from 'shared/connectors'
import { showPreloader, hidePreloader } from 'store/actions/viewManagement'
import getAuthorizedUser from './getAuthorizedUser'

const success = data => ({
  type: AUTH.LOGIN_SUCCESS,
  payload: {
    data,
  },
})

const login = loginData => async dispatch => {
  try {
    dispatch(showPreloader())
    const { data } = await authConnector.login(loginData)
    dispatch(success(data))
    await dispatch(getAuthorizedUser())
    dispatch(hidePreloader())
    return true
  } catch (err) {
    dispatch(hidePreloader())
    return false
  }
}

export default login
