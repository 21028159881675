import React from 'react'
import { string, number } from 'prop-types'

// const cleanPercentage = percentage => {
//   const tooLow = !Number.isFinite(+percentage) || percentage < 0
//   const tooHigh = percentage > 100

//   if (tooLow) {
//     return 0
//   }
//   if (tooHigh) {
//     return 100
//   }
//   return +percentage
// }

const Circle = ({ colour, pct }) => {
  const r = 70
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - pct) * circ) / 100

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ''} // remove colour as 0% sets full circumference
      strokeWidth="0.4rem"
      strokeDasharray={circ}
      strokeDashoffset={strokePct}
      strokeLinecap="round"
    />
  )
}

Circle.propTypes = {
  pct: number.isRequired,
  colour: string.isRequired,
}

const Text = ({ percentage }) => (
  <text x="50%" y="40%" dominantBaseline="central" textAnchor="middle" fontSize="1.5em">
    {percentage}%
  </text>
)

Text.propTypes = {
  percentage: number.isRequired,
}

const Time = ({ time }) => {
  let label
  if (time < 60) {
    label = `${time} sek.`
  } else {
    label = `${(time / 60).toFixed(0)} min.`
  }
  return (
    <text
      className="time"
      x="50%"
      y="55%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="0.7em"
    >
      pozostało {label}
    </text>
  )
}

Time.propTypes = {
  time: number.isRequired,
}

const Pie = ({ percentage, remainingTime, uploadSpeed }) => (
  //   const pct = cleanPercentage(percentage)
  <svg width={200} height={200}>
    <g transform={`rotate(-90 ${'100 100'})`}>
      <Circle colour="#f7f7f7" />
      <Circle colour="#00c529" pct={percentage} />
    </g>
    <Text percentage={percentage} />
    {remainingTime === null ? (
      <text
        className="time"
        x="50%"
        y="55%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize="0.7em"
      >
        Trwa obliczanie..
      </text>
    ) : (
      <>
        <Time time={remainingTime} />
        <text
          className="time"
          x="50%"
          y="65%"
          dominantBaseline="central"
          textAnchor="middle"
          fontSize="0.7em"
        >
          {uploadSpeed} mb/s
        </text>
      </>
    )}
  </svg>
)

Pie.propTypes = {
  percentage: number.isRequired,
  remainingTime: number.isRequired,
  uploadSpeed: number.isRequired,
}

export default Pie
