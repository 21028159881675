import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { node, bool } from 'prop-types'
import styles from './Popover.module.scss'

const PopoverWrapper = ({ children, opened }) => {
  useEffect(() => {
    if (opened) document.body.style.overflowY = 'hidden'

    return () => {
      document.body.style.overflowY = 'scroll'
    }
  }, [opened])

  if (opened) {
    return <div className={styles.wrapper}>{children}</div>
  }

  return null
}

PopoverWrapper.propTypes = {
  children: node.isRequired,
  opened: bool.isRequired,
}

export const Popover = props =>
  ReactDOM.createPortal(<PopoverWrapper {...props} />, document.querySelector('#root'))
