const SVG_TYPES = {
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  HOME: 'HOME',
  USERS: 'USERS',
  PREACHER: 'PREACHER',
  LOCATION: 'LOCATION',
  RECORDING: 'RECORDING',
  DOTS: 'DOTS',
  STATUS_PENDING: 'STATUS_PENDING',
  STATUS_SUCCESS: 'STATUS_SUCCESS',
  PLUS: 'PLUS',
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  TIME_BACK: 'TIME_BACK',
  TIME_FORWARD: 'TIME_FORWARD',
  DOWNLOAD: 'DOWNLOAD',
  HEART: 'HEART',
  DUPLICATE: 'DUPLICATE',
  EXCLAMATION_MARK: 'EXCLAMATION_MARK',
  LOGO: 'LOGO',
  MAGNIFIER: 'MAGNIFIER',
  CHEVRON_RIGHT: 'CHEVRON_RIGHT',
  THIN_CHEVRON_RIGHT: 'THIN_CHEVRON_RIGHT',
  EXIT: 'EXIT',
  FILTERS: 'FILTERS',
  NO_RESULTS: 'NO_RESULTS',
}

export default SVG_TYPES
