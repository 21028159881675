import React, { Suspense, lazy, useEffect } from 'react'
import { connect } from 'react-redux'
import * as AuthActions from 'store/actions/auth'
import * as PreacherActions from 'store/actions/preacher'
import * as LocationActions from 'store/actions/location'
import * as SessionActions from 'store/actions/session'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from 'shared/consts'
import { Navbar } from 'shared/components'
import { getLocalStorage } from 'shared/utils'
import { getAuthorizedUser } from 'store/selectors/authSelector'
import {
  getIsPreloaderVisible,
  getIsProgressBarVisible,
} from 'store/selectors/viewManagementSelector'
import { object, bool, func } from 'prop-types'

const Authorization = lazy(() => import('screens/authorization/Authorization.container'))
const HomePage = lazy(() => import('screens/home-page/HomePage.container'))
const AllSessions = lazy(() => import('screens/all-sessions/AllSessions.container'))
const SingleSession = lazy(() => import('screens/single-session/SingleSession.container'))
const Preloader = lazy(() => import('shared/components/preloader'))
const ProgressBar = lazy(() => import('shared/components/progressBar'))
const DashboardRoutes = lazy(() => import('screens/dashboard-routes/DashboardRoutes.container'))
const AuthRoute = lazy(() => import('shared/components/privateRoute/authRoute'))

function Routes({
  authorizedUser,
  isPreloaderVisible,
  isProgressBarVisible,
  getAuthUser,
  getPreachersOptions,
  getLocationsOptions,
  getEventsOptions,
  getCategoriesOptions,
}) {
  useEffect(() => {
    ;(async () => {
      if (getLocalStorage('isUserLogged') && !authorizedUser) {
        await getAuthUser()
      }
      getPreachersOptions()
      getLocationsOptions()
      getEventsOptions()
      getCategoriesOptions()
    })()
  }, [])

  return (
    <Suspense fallback="">
      {isPreloaderVisible && <Preloader />}
      {isProgressBarVisible && <ProgressBar />}
      <Navbar />
      <Switch>
        <Route component={HomePage} exact path={ROUTES.HOME_PAGE} />
        <Route component={Authorization} exact path={ROUTES.LOGIN} />
        <Route component={Authorization} exact path={ROUTES.REGISTER} />
        <Route component={AllSessions} exact path={ROUTES.ALL_SESSIONS} />
        <Route component={SingleSession} exact path={ROUTES.SINGLE_SESSION} />
        <AuthRoute component={DashboardRoutes} path={ROUTES.DASHBOARD} />
        <Route component={HomePage} />
      </Switch>
    </Suspense>
  )
}

Routes.defaultProps = {
  authorizedUser: null,
}

Routes.propTypes = {
  authorizedUser: object,
  isPreloaderVisible: bool.isRequired,
  isProgressBarVisible: bool.isRequired,
  getAuthUser: func.isRequired,
  getPreachersOptions: func.isRequired,
  getLocationsOptions: func.isRequired,
  getEventsOptions: func.isRequired,
  getCategoriesOptions: func.isRequired,
}

const mapDispatchToProps = {
  getAuthUser: AuthActions.getAuthorizedUser,
  getPreachersOptions: PreacherActions.getPreachersOptions,
  getLocationsOptions: LocationActions.getLocationsOptions,
  getEventsOptions: SessionActions.getEventsOptions,
  getCategoriesOptions: SessionActions.getCategoriesOptions,
}

function mapStateToProps(state) {
  return {
    authorizedUser: getAuthorizedUser(state),
    isPreloaderVisible: getIsPreloaderVisible(state),
    isProgressBarVisible: getIsProgressBarVisible(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
