import * as React from 'react'
import PropTypes from 'prop-types'
import { Error } from '../components'
import './FileField.scss'

const FileField = ({ field: { name }, form, errors, className, disabled }) => {
  const isError = !!(form.touched[name] && errors[name])
  const fieldClasses = `file-field__input ${isError ? 'file-field__input--error' : ''} ${
    disabled ? 'file-field__input--disabled' : ''
  } ${className}`

  const handleOnChange = ({ target }) => {
    form.setFieldValue(name, target.files[0])
  }

  return (
    <div className="file-field">
      <input
        name={name}
        onChange={handleOnChange}
        type="file"
        className={fieldClasses}
        accept="audio/mp3,audio/*;capture=microphone"
      />
      {isError && <Error fieldName={name} errors={errors} />}
    </div>
  )
}

FileField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

FileField.defaultProps = {
  className: '',
  disabled: false,
}

export default FileField
