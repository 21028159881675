import { VIEW_MANAGEMENT } from 'store/types'

function setProgressBarData(data) {
  return {
    type: VIEW_MANAGEMENT.SET_PROGRESS_BAR_DATA,
    payload: {
      data,
    },
  }
}

export default setProgressBarData
