import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { Svg } from 'shared/components'
import { SVG_TYPES } from 'shared/consts'
import './SelectField.scss'
import cx from 'classnames'
import { isMobile } from 'shared/utils'
import { Error } from '../components'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Svg type={SVG_TYPES.CHEVRON_DOWN} />
  </components.DropdownIndicator>
)
const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Svg type={SVG_TYPES.EXIT} />
  </components.ClearIndicator>
)

const SelectField = ({
  field: { name, value },
  form,
  errors,
  placeholder,
  options,
  // isCustomLabel,
  label,
  onChange,
  menuPlacement,
  withCustomLabel,
  isSearchable,
  isArrayValue,
  isMulti,
  isDisabled,
  className,
  menuPosition,
  onFocus,
  onBlur,
}) => {
  const isError = !!(form.touched[name] && errors[name])
  const fieldClasses = `select-field__input ${
    withCustomLabel ? 'select-field__input--with-custom-label' : ''
  } ${isError ? 'select-field__input--error' : ''} ${
    isDisabled ? 'select-field__input--disabled' : ''
  }`

  const handleChange = tempValue => {
    form.setFieldValue(name, tempValue ? tempValue.value : '')
    if (onChange) {
      // onChange(tempValue, form.setFieldValue)
      onChange()
    }
  }

  const handleArrayChange = useCallback(
    tempValue => {
      if (isMulti) {
        const multiArray = tempValue.map(val => val.value)
        form.setFieldValue(name, multiArray)
      } else form.setFieldValue(name, tempValue ? [tempValue.value] : [])
    },
    [isMulti],
  )

  // const customFilter = (option, searchText) => {
  //   if (option.data.searchableLabel.toLowerCase().includes(searchText.toLowerCase())) {
  //     return true
  //   }
  //   return false
  // }

  const getValue = () =>
    // value || value == null || value === 0
    value || value === 0 ? options && options.find(option => option.value === value) : ''

  const getArrayValue = useCallback(() => {
    if (value?.length > 0) {
      const data = value.map(val => options.find(option => option.value === val))
      return data
      // return value.find(uuid => options.find(option => option.uuid === uuid))
    }
    return []
  }, [value])

  const customStyles = useMemo(
    () => ({
      dropdownIndicator: () => ({
        display: value || value === 0 ? 'none' : 'flex',
      }),
      // menuPortal: provided => ({
      //   ...provided,
      //   zIndex: 120,
      // }),
    }),
    [value],
  )

  const customFilter = (option, searchText) => {
    const isSearchedTextMatched = searchText
      .split(' ')
      .every(text => option.data.label.toLowerCase().includes(text.toLowerCase()))
    if (isSearchedTextMatched) {
      return true
    }
    return false
  }

  const mobile = isMobile()

  return (
    <div className={cx('select-field__wrapper', className)}>
      {label && <div className="select-field__label">{label}</div>}
      <div className="select-field">
        <div className="select-field__container">
          <Select
            styles={customStyles}
            className={`react-select ${fieldClasses}`}
            classNamePrefix="react-select"
            components={{ ClearIndicator, DropdownIndicator }}
            // filterOption={isCustomLabel && customFilter}
            isClearable
            isMulti={isMulti}
            isSearchable={isSearchable}
            noOptionsMessage={() => 'Brak wyników'}
            onChange={isArrayValue ? handleArrayChange : handleChange}
            options={options}
            placeholder={placeholder}
            value={isArrayValue ? getArrayValue() : getValue()}
            menuPlacement={menuPlacement || 'bottom'}
            isDisabled={isDisabled}
            menuPosition={menuPosition}
            filterOption={isSearchable && customFilter}
            // menuPosition="fixed"
            // menuShouldBlockScroll
            openMenuOnFocus={mobile}
            onMenuOpen={onFocus}
            onMenuClose={onBlur}
          />
          {isError && <Error fieldName={name} errors={errors} />}
        </div>
      </div>
    </div>
  )
}
SelectField.propTypes = {
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  // isCustomLabel: PropTypes.bool,
  withCustomLabel: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isArrayValue: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  menuPosition: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

SelectField.defaultProps = {
  // isCustomLabel: false,
  label: '',
  menuPlacement: '',
  onChange: null,
  options: [],
  placeholder: '',
  withCustomLabel: false,
  isSearchable: true,
  isArrayValue: false,
  isMulti: false,
  isDisabled: false,
  className: '',
  menuPosition: 'auto',
  onFocus: null,
  onBlur: null,
}

export default SelectField
