import { USER } from 'store/types'

const INITIAL_STATE = {
  authorizedUser: {
    data: null,
  },
  users: {
    data: undefined,
  },
  selectedUser: {
    data: undefined,
  },
}

const userReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case USER.REGISTER_USER_SUCCESS: {
      return {
        ...state,
      }
    }
    case USER.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          data: payload.data,
        },
      }
    }
    case USER.SET_SELECTED_USER_SUCCESS: {
      return {
        ...state,
        selectedUser: {
          data: payload.data,
        },
      }
    }
    default:
      return state
  }
}

export default userReducer
