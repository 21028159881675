import { AUDIO_STATUSES } from 'shared/consts'
import { SVG_TYPES } from '../../consts'

export const getIconType = status => {
  switch (status) {
    case AUDIO_STATUSES.DUPLICATE:
      return SVG_TYPES.DUPLICATE
    case AUDIO_STATUSES.ERROR:
      return SVG_TYPES.EXCLAMATION_MARK
    default:
      return SVG_TYPES.STATUS_PENDING
  }
}

export const getTooltipLabel = status => {
  switch (status) {
    case AUDIO_STATUSES.DUPLICATE:
      return 'Duplikat pliku audio'
    case AUDIO_STATUSES.ERROR:
      return 'Błąd pliku audio'
    case AUDIO_STATUSES.CONVERSION_RESTART_POSSIBLE:
      return 'Audio wymaga ponownego przetworzenia'
    default:
      return 'Audio w trakcie konwersji'
  }
}
