import USER_ROLES from './userRoles'

const USER_ROLES_LABELS = {
  [USER_ROLES.ADMIN]: 'Administrator',
  [USER_ROLES.MODERATOR]: 'Moderator',
  [USER_ROLES.SUPER_UPLOADER]: 'Super uploader',
  [USER_ROLES.UPLOADER]: 'Uploader',
  [USER_ROLES.USER]: 'Użytkownik',
}

export default USER_ROLES_LABELS
