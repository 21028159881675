import React from 'react'
import cx from 'classnames'

import { string, number } from 'prop-types'
import styles from './Skeleton.module.scss'

export const Skeleton = ({ height, width, borderRadius = 'default', className }) => {
  const dimensions = {
    width: width ? `${width}px` : '',
    height: height ? `${height}px` : '',
  }

  return (
    <div className={cx(styles.wrapper, className)} style={dimensions}>
      <div className={cx(styles.animation, styles[borderRadius])} />
      <div className={cx(styles.item, styles[borderRadius])} />
    </div>
  )
}

Skeleton.defaultProps = {
  height: 0,
  width: 0,
  borderRadius: 'default',
  className: '',
}

Skeleton.propTypes = {
  height: number,
  width: number,
  borderRadius: string,
  className: string,
}
