const ROUTES = {
  HOME_PAGE: '/',
  LOGIN: '/logowanie',
  REGISTER: '/rejestracja',
  DASHBOARD: '/dashboard',
  USERS: '/dashboard/users',
  PREACHERS: '/dashboard/preachers',
  LOCATIONS: '/dashboard/locations',
  SESSIONS: '/dashboard/sessions',
  MY_SESSIONS: '/dashboard/my-sessions',
  ALL_SESSIONS: '/all-sessions',
  SINGLE_SESSION: '/single-session/:uuid',
  HISTORY: '/dashboard/history',
}

export default ROUTES
