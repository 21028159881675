import { useCallback, useState } from 'react'

const useRowModal = (initialState = null) => {
  const [value, setValue] = useState(initialState)

  const toggle = useCallback((newValue = null) => {
    setValue(newValue)
  }, [])

  return [value, toggle]
}

export default useRowModal
