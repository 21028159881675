export const FILTERS_PARAMS_ARRAY = [
  'name',
  'query',
  'preacher',
  'location',
  'category',
  'event',
  'sort',
  'date_start',
  'date_end',
  'status',
]
