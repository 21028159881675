import React from 'react'
import './TotalAmount.scss'
import { node } from 'prop-types'

function TotalAmount({ children }) {
  return <div className="total-amount">{children}</div>
}

TotalAmount.propTypes = {
  children: node.isRequired,
}

export default TotalAmount
