import { PREACHER } from 'store/types'
import { preacherConnector } from 'shared/connectors'
import { showPreloader, hidePreloader } from 'store/actions/viewManagement'
import { getSelectedRow } from 'store/selectors/viewManagementSelector'
import { updateSelectedRow } from '../viewManagement'

const success = () => ({
  type: PREACHER.ADD_PREACHER_SUCCESS,
})

const addPreacher = preacherData => async (dispatch, getState) => {
  try {
    dispatch(showPreloader())

    const { error = false } = getSelectedRow(getState())
    await preacherConnector.addPreacher(preacherData, error)

    dispatch(success())
    dispatch(hidePreloader())
    return true
  } catch ({ response: { data } }) {
    dispatch(updateSelectedRow(data))
    dispatch(hidePreloader())
    return false
  }
}

export default addPreacher
