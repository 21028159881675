import { SESSION } from 'store/types'
import { sessionConnector } from 'shared/connectors'
import {
  showPreloader,
  hidePreloader,
  showProgressBar,
  hideProgressBar,
} from 'store/actions/viewManagement'
import { getSelectedRow } from 'store/selectors/viewManagementSelector'
import { updateSelectedRow } from '../viewManagement'

const success = () => ({
  type: SESSION.EDIT_SESSION_SUCCESS,
})

const editSession = sessionData => async (dispatch, getState) => {
  try {
    dispatch(showPreloader())
    const { error = false } = getSelectedRow(getState())

    await sessionConnector.editSession(sessionData, error)

    if (sessionData.new_audio !== undefined) {
      const audioFile = new FormData()
      const cloneAudio = sessionData
      audioFile.append('recording', cloneAudio.new_audio)
      delete cloneAudio.new_audio

      dispatch(showProgressBar())
      await sessionConnector.addAudioToSession(audioFile, sessionData.uuid)
      dispatch(hideProgressBar())
    }

    dispatch(success())
    dispatch(hidePreloader())
    return true
  } catch ({ response: { data } }) {
    dispatch(updateSelectedRow(data))
    dispatch(hidePreloader())
    return false
  }
}

export default editSession
