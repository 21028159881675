import React from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { string } from 'prop-types'
import { Svg } from 'shared/components'
import { SVG_TYPES, ROUTES } from '../../consts'
import './Play.scss'

function Play({ uuid }) {
  const { push } = useHistory()

  return (
    <div
      data-testid="play"
      className="play"
      onClick={() => push(generatePath(ROUTES.SINGLE_SESSION, { uuid }))}
      data-tip="Odtwórz"
    >
      <Svg type={SVG_TYPES.PLAY} />
    </div>
  )
}

Play.propTypes = {
  uuid: string.isRequired,
}

export default Play
