import React from 'react'
import { func, bool } from 'prop-types'
import './Hamburger.scss'

function Hamburger({ isExpanded, setIsExpanded }) {
  return (
    <div className="hamburger">
      <div
        className={`hamburger__bars ${isExpanded && 'hamburger__bars--active'}`}
        onClick={setIsExpanded}
      >
        <svg viewBox="0 0 64 48">
          <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37" />
          <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24" />
          <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37" />
        </svg>
      </div>
      {isExpanded && <div className="hamburger__logo">Wykłady Biblijne</div>}
    </div>
  )
}

Hamburger.propTypes = {
  setIsExpanded: func.isRequired,
  isExpanded: bool.isRequired,
}

export default Hamburger
