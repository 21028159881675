import React from 'react'
import PropTypes from 'prop-types'
import { MODAL_TYPES, BUTTON_THEMES, BUTTON_SIZES } from 'shared/consts'
import { Button } from 'shared/components'

function Actions({ type, handleSubmit, onClose, isDisabled }) {
  return (
    <div className="modal__actions">
      <Button
        theme={BUTTON_THEMES.TRANSPARENT}
        size={BUTTON_SIZES.SMALL}
        label="Anuluj"
        onClick={onClose}
      />
      {type === MODAL_TYPES.ADD && (
        <Button
          theme={BUTTON_THEMES.PRIMARY}
          size={BUTTON_SIZES.SMALL}
          label="Dodaj"
          onClick={handleSubmit}
          isDisabled={isDisabled}
        />
      )}
      {type === MODAL_TYPES.EDIT && (
        <Button
          theme={BUTTON_THEMES.PRIMARY}
          size={BUTTON_SIZES.SMALL}
          label="Edytuj"
          onClick={handleSubmit}
          isDisabled={isDisabled}
        />
      )}
      {type === MODAL_TYPES.DELETE && (
        <Button
          theme={BUTTON_THEMES.DELETE}
          size={BUTTON_SIZES.SMALL}
          label="Tak, usuń"
          onClick={handleSubmit}
          isDisabled={isDisabled}
        />
      )}
    </div>
  )
}

Actions.defaultProps = {
  isDisabled: false,
}

Actions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  type: PropTypes.oneOf([MODAL_TYPES.ADD, MODAL_TYPES.EDIT, MODAL_TYPES.DELETE]).isRequired,
}

export default Actions
