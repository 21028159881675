import { locationConnector } from 'shared/connectors'
import { LOCATION } from 'store/types'

const success = data => ({
  type: LOCATION.GET_LOCATIONS_OPTIONS_SUCCESS,
  payload: {
    data,
  },
})

const failure = () => ({
  type: LOCATION.GET_LOCATIONS_OPTIONS_FAILURE,
})

const getLocationsOptions = () => async dispatch => {
  try {
    const { data } = await locationConnector.getLocationsOptions()
    dispatch(success(data))
  } catch (err) {
    dispatch(failure())
  }
}

export default getLocationsOptions
