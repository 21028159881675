import { LOCATION } from 'store/types'
import { locationConnector } from 'shared/connectors'
import { showPreloader, hidePreloader } from 'store/actions/viewManagement'

const success = () => ({
  type: LOCATION.DELETE_LOCATION_SUCCESS,
})

const deleteLocation = uuid => async dispatch => {
  try {
    dispatch(showPreloader())

    await locationConnector.deleteLocation(uuid)

    dispatch(success())
    dispatch(hidePreloader())
  } catch (err) {
    dispatch(hidePreloader())
  }
}

export default deleteLocation
