import React from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'

function Button({ type, theme, label, onClick, fullWidth, size, isDisabled }) {
  return (
    <button
      type={type}
      disabled={isDisabled}
      className={`${styles.button} ${styles[theme]} ${fullWidth && styles.fullWidth} ${
        styles[size]
      } ${isDisabled && styles.disabled}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  fullWidth: false,
  isDisabled: false,
  onClick: null,
}

export default Button
