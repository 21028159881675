import { useEffect, useCallback } from 'react'
import { useQueryParams } from 'shared/hooks'

export const usePagination = data => {
  const {
    params: { page },
    params,
    editParams,
  } = useQueryParams()

  const updateURL = useCallback(
    ({ page: pageNumber, limit }) => {
      editParams([
        { key: 'page', value: pageNumber },
        { key: 'limit', value: limit },
      ])
    },
    [params, editParams],
  )

  useEffect(() => {
    if (!page) {
      editParams([
        { key: 'page', value: 1 },
        { key: 'limit', value: 20 },
      ])
    }
  }, [data])

  return { updateURL, params }
}
