const LOCATION = {
  GET_LOCATIONS_FAILURE: 'GET_LOCATIONS_FAILURE',
  GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_OPTIONS_FAILURE: 'GET_LOCATIONS_OPTIONS_FAILURE',
  GET_LOCATIONS_OPTIONS_SUCCESS: 'GET_LOCATIONS_OPTIONS_SUCCESS',
  SET_SELECTED_LOCATION_SUCCESS: 'SET_SELECTED_LOCATION_SUCCESS',
  ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
  ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',
  EDIT_LOCATION_SUCCESS: 'EDIT_LOCATION_SUCCESS',
  EDIT_LOCATION_FAILURE: 'EDIT_LOCATION_FAILURE',
  DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
  DELETE_LOCATION_FAILURE: 'DELETE_LOCATION_FAILURE',
}

export default LOCATION
