import { SESSION } from 'store/types'
import { sessionConnector } from 'shared/connectors'
import { showPreloader, hidePreloader } from 'store/actions/viewManagement'

const success = () => ({
  type: SESSION.DELETE_SESSION_SUCCESS,
})

const deleteSession = uuid => async dispatch => {
  try {
    dispatch(showPreloader())
    await sessionConnector.deleteSession(uuid)

    dispatch(success())
    dispatch(hidePreloader())
  } catch (err) {
    dispatch(hidePreloader())
  }
}

export default deleteSession
