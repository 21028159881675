import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import authReducer from './authReducer'
import viewManagementReducer from './viewManagementReducer'
import userReducer from './userReducer'
import preacherReducer from './preacherReducer'
import locationReducer from './locationReducer'
import sessionReducer from './sessionReducer'

export const historyBrowser = createBrowserHistory()

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    authStore: authReducer,
    viewManagementStore: viewManagementReducer,
    userStore: userReducer,
    preacherStore: preacherReducer,
    locationStore: locationReducer,
    sessionStore: sessionReducer,
  })

export default rootReducer
