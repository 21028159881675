import { sessionConnector } from 'shared/connectors'
import { SESSION } from 'store/types'

const init = () => ({
  type: SESSION.GET_SESSIONS_SEARCH_INIT,
})

const success = (data, params) => ({
  type: SESSION.GET_SESSIONS_SEARCH_SUCCESS,
  payload: {
    data,
    params,
  },
})

const failure = () => ({
  type: SESSION.GET_SESSIONS_SEARCH_FAILURE,
})

const getSessionsSearch = params => async dispatch => {
  dispatch(init())
  try {
    const { data } = await sessionConnector.getSessionsSearch(params)
    // console.log(params, 'GET SESSION SEARCH')
    dispatch(success(data, params))
  } catch (err) {
    dispatch(failure())
  }
}

export default getSessionsSearch
