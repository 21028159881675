import React, { useCallback } from 'react'

import { ROUTES } from 'shared/consts'
import { useHistory } from 'react-router-dom'
import { bool, func } from 'prop-types'

function Dropdown({ logout, isHomePage }) {
  const { push } = useHistory()

  const handleDashboardRedirect = useCallback(() => push(ROUTES.DASHBOARD), [])

  return (
    <div className="topbar__dropdown">
      {isHomePage && (
        <div className="topbar__dropdown-item" onClick={handleDashboardRedirect}>
          Panel użytkownika
        </div>
      )}
      <div className="topbar__dropdown-item topbar__dropdown-item--red" onClick={logout}>
        Wyloguj się
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  logout: func.isRequired,
  isHomePage: bool.isRequired,
}

export default Dropdown
