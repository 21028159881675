import React from 'react'
import { Svg } from 'shared/components'
import { SVG_TYPES, ROUTES } from 'shared/consts'
import { isMobile } from 'shared/utils'
import { useLocation, Link } from 'react-router-dom'
import styles from './Navbar.module.scss'
import { MobileNavigation, DesktopNavigation } from './components'

export const Navbar = () => {
  const isMobileRes = isMobile()
  const { pathname } = useLocation()

  if (
    pathname.indexOf('dashboard') > -1 ||
    pathname === ROUTES.LOGIN ||
    pathname === ROUTES.REGISTER
  )
    return null

  return (
    <div className={styles.wrapper}>
      <Link to={ROUTES.HOME_PAGE}>
        <div className={styles.logo}>
          <Svg type={SVG_TYPES.LOGO} />
          <h1>Wykłady Biblijne</h1>
        </div>
      </Link>

      {isMobileRes ? <MobileNavigation /> : <DesktopNavigation />}
    </div>
  )
}
