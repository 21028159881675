import React from 'react'
import { string } from 'prop-types'
import './Description.scss'

function Description({ value }) {
  return <div className="description">{value}</div>
}

Description.propTypes = {
  value: string.isRequired,
}

export default Description
