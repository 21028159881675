import React from 'react'
import './Preloader.scss'

function Preloader() {
  return (
    <div data-testid="preloader" className="preloader">
      <svg viewBox="0 0 50 50">
        <circle className="path1" cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
        <circle className="path2" cx="25" cy="25" r="10" fill="none" strokeWidth="3" />
      </svg>
    </div>
  )
}

export default Preloader
