const USER = {
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  SET_SELECTED_USER_SUCCESS: 'SET_SELECTED_USER_SUCCESS',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
}

export default USER
