import React from 'react'
import { string } from 'prop-types'
import './Title.scss'

function Title({ value }) {
  return <h1 className="title">{value}</h1>
}

Title.propTypes = {
  value: string.isRequired,
}

export default Title
