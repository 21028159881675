import React from 'react'
import { string } from 'prop-types'

function GroupWrapper({ label }) {
  return (
    <div className="modal__group-wrapper">
      <div className="modal__group-label">{label}</div>
    </div>
  )
}

GroupWrapper.propTypes = {
  label: string.isRequired,
}

export default GroupWrapper
