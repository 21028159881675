import { createSelector } from 'reselect'

const getViewManagementStore = state => state.viewManagementStore
const getIsPreloaderVisible = createSelector(
  getViewManagementStore,
  ({ isPreloaderVisible }) => isPreloaderVisible,
)
const getIsProgressBarVisible = createSelector(
  getViewManagementStore,
  ({ isProgressBarVisible }) => isProgressBarVisible,
)
const getSelectedRow = createSelector(
  getViewManagementStore,
  ({ selectedRow: { data = {} } }) => data,
)
const getProgressBarData = createSelector(
  getViewManagementStore,
  ({ progressBarData = {} }) => progressBarData,
)

export { getIsPreloaderVisible, getSelectedRow, getIsProgressBarVisible, getProgressBarData }
