import { VIEW_MANAGEMENT } from 'store/types'

const INITIAL_STATE = {
  isPreloaderVisible: false,
  isProgressBarVisible: false,
  selectedRow: {
    data: undefined,
  },
  progressBarData: {
    percentage: 0,
    remainingTime: null,
    loaded: 0,
    timeStamp: 0,
  },
}

const viewManagementReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case VIEW_MANAGEMENT.SHOW_PRELOADER:
      return {
        ...state,
        isPreloaderVisible: true,
      }
    case VIEW_MANAGEMENT.HIDE_PRELOADER:
      return {
        ...state,
        isPreloaderVisible: false,
      }
    case VIEW_MANAGEMENT.SHOW_PROGRESS_BAR:
      return {
        ...state,
        isProgressBarVisible: true,
      }
    case VIEW_MANAGEMENT.HIDE_PROGRESS_BAR:
      return {
        ...state,
        isProgressBarVisible: false,
        progressBarData: {
          percentage: 0,
          remainingTime: 0,
          loaded: 0,
          timeStamp: 0,
        },
      }
    case VIEW_MANAGEMENT.SET_SELECTED_ROW: {
      return {
        ...state,
        selectedRow: {
          data: payload.data,
        },
      }
    }
    case VIEW_MANAGEMENT.UPDATE_SELECTED_ROW: {
      return {
        ...state,
        selectedRow: {
          data: {
            ...state.selectedRow.data,
            ...payload.data,
          },
        },
      }
    }
    case VIEW_MANAGEMENT.SET_PROGRESS_BAR_DATA: {
      let timeElapsed
      let uploadSpeed
      let time = 0
      if (state.progressBarData.timeStamp) {
        timeElapsed = Date.now() - state.progressBarData.timeStamp // Assuming that timeStarted is a Date Object
        uploadSpeed = Number((payload.data.loaded / 1024 / 1024 / (timeElapsed / 1000)).toFixed(2)) // Upload speed in second
        time = Number(
          ((payload.data.total - payload.data.loaded) / 1024 / 1024 / uploadSpeed).toFixed(0),
        )
      } else {
        // eslint-disable-next-line no-param-reassign
        state.progressBarData.timeStamp = Date.now()
      }

      return {
        ...state,
        progressBarData: {
          ...state.progressBarData,
          percentage: Number(((payload.data.loaded / payload.data.total) * 100).toFixed(0)),
          remainingTime: time,
          uploadSpeed,
        },
      }
    }
    default:
      return state
  }
}

export default viewManagementReducer
