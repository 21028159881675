import { VIEW_MANAGEMENT } from 'store/types'

const updateSelectedRow = data => ({
  type: VIEW_MANAGEMENT.UPDATE_SELECTED_ROW,
  payload: {
    data,
  },
})

export default updateSelectedRow
