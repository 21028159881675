import React, { useCallback } from 'react'

import { string, array } from 'prop-types'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'shared/consts'
import styles from './Tag.module.scss'

export const Tag = ({ title, color, item, paramProperties }) => {
  const { push } = useHistory()
  const params = {}

  paramProperties.forEach(({ key, param }) => {
    params[param] = item[key]
  })

  const query = new URLSearchParams(params)
  const handleOnClick = useCallback(
    () =>
      push({
        pathname: ROUTES.ALL_SESSIONS,
        search: query.toString(),
      }),
    [],
  )
  return (
    <div className={cx(styles.wrapper, styles[color])} onClick={handleOnClick}>
      {title}
    </div>
  )
}

Tag.propTypes = {
  title: string.isRequired,
  color: string.isRequired,
  paramProperties: array.isRequired,
  item: array.isRequired,
}
