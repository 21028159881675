import React from 'react'
import { NavLink } from 'react-router-dom'
import { bool, string } from 'prop-types'
import { Svg } from 'shared/components'
import '../Navigation.scss'

function NavigationItem({ isExpanded, path, icon, label }) {
  return (
    <NavLink to={path} className="navigation__item" exact>
      <div className="navigation__item-wrapper">
        <span className="navigation__icon">
          <Svg type={icon} />
        </span>
      </div>
      {isExpanded && <div>{label}</div>}
    </NavLink>
  )
}

NavigationItem.propTypes = {
  isExpanded: bool.isRequired,
  path: string.isRequired,
  icon: string.isRequired,
  label: string.isRequired,
}

export default NavigationItem
