import API from 'shared/connectors/config'

export const getPreachers = params => API.get('preachers', { params })

export const getPreachersOptions = () => API.get('preachers/options')

export const addPreacher = (data, error) => API.post(`preachers${error ? '?force=true' : ''}`, data)

export const editPreacher = (data, error) =>
  API.put(`preachers/${data.uuid}${error ? '?force=true' : ''}`, data)

export const deletePreacher = uuid => API.delete(`preachers/${uuid}`)
