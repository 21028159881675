import React from 'react'
import { string } from 'prop-types'
import { NavLink } from 'react-router-dom'
import styles from './Link.module.scss'

function Link({ to, label }) {
  return (
    <NavLink to={to} className={styles.link}>
      {label}
    </NavLink>
  )
}

Link.propTypes = {
  to: string.isRequired,
  label: string.isRequired,
}

export default Link
