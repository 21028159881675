import React, { useCallback, useRef } from 'react'
import { array, bool, func } from 'prop-types'
import { Field, Formik } from 'formik'
import { SelectField, RegularField } from 'shared/components'
import { useDebouncedCallback, useQueryParams } from 'shared/hooks'
import { FIELD_TYPES } from 'shared/consts'
import styles from './TableFilters.module.scss'
import { useTableFilters } from './hooks'

export const TableFilters = ({ filters, fetchData, isSearchable }) => {
  const formRef = useRef()
  const { editParams } = useQueryParams()

  const { initialValues } = useTableFilters(formRef)

  const handleNameSearch = useCallback(
    useDebouncedCallback(() => formRef.current.handleSubmit(), 500),
    [],
  )

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async values => {
        await fetchData({ ...values, page: 1, limit: 20 })
        const keys = Object.keys(values)
        const params = keys.map(key => ({ key, value: values[key] }))

        editParams([...params, { key: 'page', value: 1 }, { key: 'limit', value: 20 }])
      }}
    >
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.fieldsWrapper}>
            {isSearchable && (
              <div className={styles.searchField}>
                <Field
                  component={RegularField}
                  errors={errors}
                  placeholder="Wpisz tytuł"
                  name="query"
                  type={FIELD_TYPES.TEXT}
                  onChange={handleNameSearch}
                  onClear={formRef.current?.handleSubmit}
                  isClearable
                />
              </div>
            )}
            {filters.map(filter => (
              <Field
                component={SelectField}
                className={styles.filterField}
                errors={errors}
                onChange={handleSubmit}
                {...filter}
                name={filter.key}
              />
            ))}
          </div>
        </form>
      )}
    </Formik>
  )
}

TableFilters.propTypes = {
  filters: array.isRequired,
  fetchData: func.isRequired,
  isSearchable: bool.isRequired,
}
