import * as React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { MODAL_TYPES } from 'shared/consts'
import './Modal.scss'

class Modal extends React.PureComponent {
  // constructor(props) {
  //   super(props)
  //   const { opened } = this.props
  //   this.state = {
  //     isOpened: opened || false,
  //   }
  // }

  componentDidMount() {
    const { onOpen } = this.props
    if (onOpen) onOpen()
  }

  // shouldComponentUpdate(nextProps) {
  //   const { opened } = this.props
  //   if (nextProps.opened !== opened) {
  //     this.setOpened(nextProps.opened)
  //   }
  //   return true
  // }

  // componentWillUnmount() {
  //   const { onClose } = this.props
  //   if (onClose) onClose()
  // }

  // setOpened = isOpened => {
  //   const { onOpenedChange } = this.props
  //   this.setState({ isOpened }, () => onOpenedChange && onOpenedChange(isOpened))
  // }

  submit = () => {
    const { onSubmit, onClose } = this.props
    if (onSubmit) onSubmit()
    // this.setOpened(false)
    onClose()
  }

  close = () => {
    const { onClose } = this.props
    if (onClose) onClose()
  }

  handleChildClick = e => {
    e.stopPropagation()
  }

  render() {
    // const { isOpened } = this.state
    const { children, className, title, type, opened } = this.props
    return (
      <>
        {opened && (
          <div
            className={`modal ${type === MODAL_TYPES.DELETE ? 'modal--delete' : ''}`}
            onClick={this.close}
          >
            <div
              className={`modal__inner modal__inner--${className || ''}`}
              onClick={this.handleChildClick}
            >
              <div className="modal__header">
                <p className="modal__title">{title}</p>
              </div>
              <div className="modal__content">{children}</div>
            </div>
          </div>
        )}
      </>
    )
  }
}

Modal.defaultProps = {
  className: '',
  title: '',
  onOpen: null,
  type: null,
  // onOpenedChange: null,
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  // onOpenedChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf([MODAL_TYPES.ADD, MODAL_TYPES.EDIT, MODAL_TYPES.DELETE, null]),
}

export default props => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('#root'))
