import React from 'react'
import { Status } from 'shared/components'

const STATUS_OPTIONS = [
  {
    label: (
      <div className="select-field__option">
        <Status isVerified={1} /> Zweryfikowano
      </div>
    ),
    value: 1,
  },
  {
    label: (
      <div className="select-field__option">
        <Status isVerified={0} /> W trakcie weryfikacji
      </div>
    ),
    value: 0,
  },
]

export default STATUS_OPTIONS
