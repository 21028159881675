import { AUTH } from 'store/types'
import { authConnector } from 'shared/connectors'
import { showPreloader, hidePreloader } from 'store/actions/viewManagement'

const success = () => ({
  type: AUTH.LOGOUT_SUCCESS,
})

const logout = () => async dispatch => {
  try {
    dispatch(showPreloader())
    const { data } = await authConnector.logout()
    dispatch(success(data))
    dispatch(hidePreloader())
  } catch (err) {
    dispatch(hidePreloader())
  }
}

export default logout
