import { SESSION } from 'store/types'

const INITIAL_STATE = {
  sessions: {
    data: undefined,
  },
  lastAddedSessions: {
    data: undefined,
  },
  eventsOptions: {
    data: undefined,
  },
  categoriesOptions: {
    data: undefined,
  },
  searchedSessions: {
    data: undefined,
    params: undefined,
    isFetching: false,
  },
  singleSession: {
    data: undefined,
    isFetching: false,
  },
  history: {
    data: undefined,
  },
}

const sessionReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case SESSION.GET_SESSIONS_SUCCESS: {
      return {
        ...state,
        sessions: {
          data: payload.data,
        },
      }
    }
    case SESSION.GET_LAST_ADDED_SESSIONS_SUCCESS: {
      return {
        ...state,
        lastAddedSessions: {
          data: payload.data,
        },
      }
    }
    case SESSION.GET_EVENTS_OPTIONS_SUCCESS: {
      return {
        ...state,
        eventsOptions: {
          data: payload.data,
        },
      }
    }
    case SESSION.GET_CATEGORIES_OPTIONS_SUCCESS: {
      return {
        ...state,
        categoriesOptions: {
          data: payload.data,
        },
      }
    }
    case SESSION.GET_SESSIONS_SEARCH_INIT: {
      return {
        ...state,
        searchedSessions: {
          ...state.searchedSessions,
          isFetching: true,
        },
      }
    }
    case SESSION.GET_SESSIONS_SEARCH_SUCCESS: {
      if (payload.params.page) {
        payload.params.page = String(payload.params.page)
      }
      if (payload.params.limit) {
        payload.params.limit = String(payload.params.limit)
      }
      return {
        ...state,
        searchedSessions: {
          data: payload.data,
          params: {
            ...payload.params,
          },
          isFetching: false,
        },
      }
    }
    case SESSION.GET_SINGLE_SESSION_INIT: {
      return {
        ...state,
        singleSession: {
          data: undefined,
          isFetching: true,
        },
      }
    }
    case SESSION.GET_SINGLE_SESSION_SUCCESS: {
      return {
        ...state,
        singleSession: {
          data: payload.data,
          isFetching: false,
        },
      }
    }
    case SESSION.CLEAR_SINGLE_SESSION: {
      return {
        ...state,
        singleSession: {
          data: undefined,
        },
      }
    }
    case SESSION.ADD_SESSION_HISTORY_SUCCESS: {
      return {
        ...state,
        singleSession: {
          data: { ...state.singleSession.data, history: { ...payload.data } },
        },
      }
    }
    case SESSION.GET_SESSIONS_HISTORY_SUCCESS: {
      return {
        ...state,
        history: {
          data: payload.data,
        },
      }
    }
    default:
      return state
  }
}

export default sessionReducer
