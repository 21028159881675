import React from 'react'
import { Skeleton } from 'shared/components'

export const RowPlaceholders = () => (
  <>
    {Array.from(Array(8), item => (
      <tr key={item} className="placeholderRow">
        <td colSpan={20}>
          <Skeleton height={58} />
        </td>
      </tr>
    ))}
  </>
)
