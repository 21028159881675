import { useEffect } from 'react'

const useOutsideClick = (ref, callback, enabled = true) => {
  const handleClick = event => {
    if (ref.current && !ref.current.contains(event.target) && enabled) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref, callback, enabled])
}

export default useOutsideClick
