import React from 'react'
import './Sidebar.scss'
import { func, bool, string } from 'prop-types'
import { Hamburger, Navigation } from './components'

function Sidebar({ authorizedUserRole, isExpanded, setIsExpanded }) {
  return (
    <div className={`sidebar ${isExpanded && 'sidebar--expanded'}`}>
      <Hamburger isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <Navigation isExpanded={isExpanded} authorizedUserRole={authorizedUserRole} />
    </div>
  )
}
Sidebar.defaultProps = {
  authorizedUserRole: '',
}

Sidebar.propTypes = {
  authorizedUserRole: string,
  setIsExpanded: func.isRequired,
  isExpanded: bool.isRequired,
}

export default Sidebar
