import React from 'react'

import { string, func, bool } from 'prop-types'
import { Svg } from 'shared/components'
import { SVG_TYPES } from 'shared/consts'
import cx from 'classnames'
import styles from './PopoverTopBar.module.scss'

export const PopoverTopBar = ({ onReset, onClose, title, isDisabled }) => (
  <div className={styles.wrapper}>
    <div
      onClick={!isDisabled && onReset}
      className={cx(styles.reset, { [styles.disabled]: isDisabled })}
    >
      Wyczyść
    </div>
    <h2>{title}</h2>
    <div onClick={onClose} className={styles.exit}>
      <Svg type={SVG_TYPES.EXIT} />
    </div>
  </div>
)

PopoverTopBar.propTypes = {
  onReset: func.isRequired,
  onClose: func.isRequired,
  title: string.isRequired,
  isDisabled: bool.isRequired,
}
