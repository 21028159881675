import ROUTES from './routes'
import USER_ROLES from './userRoles'

const { ADMIN, MODERATOR, SUPER_UPLOADER, UPLOADER, USER } = USER_ROLES

const PROTECTED_ROUTES = new Map([
  [ROUTES.HOME_PAGE, [ADMIN, MODERATOR, SUPER_UPLOADER, UPLOADER, USER]],
  [ROUTES.USERS, [ADMIN]],
  [ROUTES.PREACHERS, [ADMIN, MODERATOR, SUPER_UPLOADER, UPLOADER]],
  [ROUTES.LOCATIONS, [ADMIN, MODERATOR, SUPER_UPLOADER, UPLOADER]],
  [ROUTES.SESSIONS, [ADMIN, MODERATOR, SUPER_UPLOADER, UPLOADER]],
  [ROUTES.MY_SESSIONS, [ADMIN, MODERATOR, SUPER_UPLOADER, UPLOADER]],
  [ROUTES.HISTORY, [ADMIN, MODERATOR, SUPER_UPLOADER, UPLOADER, USER]],
])

export default PROTECTED_ROUTES
