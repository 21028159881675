import { preacherConnector } from 'shared/connectors'
import { PREACHER } from 'store/types'

const success = data => ({
  type: PREACHER.GET_PREACHERS_SUCCESS,
  payload: {
    data,
  },
})

const failure = () => ({
  type: PREACHER.GET_PREACHERS_FAILURE,
})

const getPreachers = params => async dispatch => {
  try {
    const { data } = await preacherConnector.getPreachers(params)
    dispatch(success(data))
  } catch (err) {
    dispatch(failure())
  }
}

export default getPreachers
