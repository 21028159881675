import { locationConnector } from 'shared/connectors'
import { LOCATION } from 'store/types'

const success = data => ({
  type: LOCATION.GET_LOCATIONS_SUCCESS,
  payload: {
    data,
  },
})

const failure = () => ({
  type: LOCATION.GET_LOCATIONS_FAILURE,
})

const getLocations = params => async dispatch => {
  try {
    const { data } = await locationConnector.getLocations(params)
    dispatch(success(data))
  } catch (err) {
    dispatch(failure())
  }
}

export default getLocations
