import API from 'shared/connectors/config'
import store from 'store/store'
import { setProgressBarData } from 'store/actions/viewManagement'

// export const getSessions = uploadedBy =>
//   API.get(`sessions${uploadedBy ? `?uploaded_by=${uploadedBy}` : ''}`)
export const getSessions = params => API.get(`sessions`, { params })

export const getLastAddedSessions = limit => API.get(`sessions/last-added?limit=${limit}`)

export const addSession = (data, error) => API.post(`sessions${error ? '?force=true' : ''}`, data)

export const editSession = (data, error) =>
  API.put(`sessions/${data.uuid}${error ? '?force=true' : ''}`, data)

export const deleteSession = uuid => API.delete(`sessions/${uuid}`)

export const getSessionsSearch = params => API.get(`sessions/search`, { params })

export const getSingleSession = uuid => API.get(`sessions/${uuid}`)

export const addSessionHistory = data => API.post(`history`, data)

export const getSessionsHistory = params => API.get(`history`, { params })

// AUDIO
export const addAudioToSession = (data, uuid) =>
  API.post(`sessions/${uuid}/audio`, data, {
    onUploadProgress: progressEvent => {
      store.dispatch(setProgressBarData(progressEvent))
    },
  })

// EVENTS
export const getEventsOptions = () => API.get('events/options')

// CATEGORIES
export const getCategoriesOptions = () => API.get('categories/options')
