import React, { useEffect, useState } from 'react'
import { object, func } from 'prop-types'
import { Svg } from 'shared/components'
import { SVG_TYPES } from 'shared/consts'
import cx from 'classnames'
import { usePagination } from 'shared/hooks/usePaginationV2'
import styles from './Pagination.module.scss'

export const Pagination = ({ meta, fetchData }) => {
  const [items, setItems] = useState([])
  const { current_page, first_page, last_page } = meta
  // const last_page = 15
  const limit = 20
  const tempItems = Array.from({ length: last_page }, (_, i) => i + 1)

  const isPreviousDisabled = current_page === first_page
  const isNextDisabled = current_page === last_page

  const { params, updateURL } = usePagination(meta)

  const handleFetch = ({ page }) => {
    fetchData({ ...params, page, limit })
    updateURL({ page, limit })
  }

  const handlePrevious = () => !isPreviousDisabled && handleFetch({ page: current_page - 1 })
  const handleNext = () => !isNextDisabled && handleFetch({ page: current_page + 1 })
  // eslint-disable-next-line no-restricted-globals
  const handleItem = page => () => !isNaN(page) && handleFetch({ page })

  useEffect(() => {
    let copyTempItems = [...tempItems]

    if (last_page >= 6) {
      copyTempItems = [1, 2, 3, '...', tempItems.length]
    }
    if (last_page >= 6 && current_page >= tempItems.length - 2) {
      const sliced = tempItems.slice(-3)
      copyTempItems = [1, '...', ...sliced]
    }
    if (last_page >= 6 && current_page >= 4 && current_page < tempItems.length - 2) {
      copyTempItems = [1, '...', current_page, '...', last_page]
    }

    setItems(copyTempItems)
  }, [meta])

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.chevron, styles.box, {
          [styles.disabled]: isPreviousDisabled,
        })}
        onClick={handlePrevious}
      >
        <Svg type={SVG_TYPES.THIN_CHEVRON_RIGHT} />
      </div>
      <div className={styles.itemsWrapper}>
        {items.map(page => (
          <div
            className={cx(styles.box, {
              [styles.active]: page === current_page,
              [styles.disabled]: typeof page !== 'number',
            })}
            onClick={handleItem(page)}
          >
            {page}
          </div>
        ))}
      </div>
      <div
        className={cx(styles.chevron, styles.box, {
          [styles.disabled]: isNextDisabled,
        })}
        onClick={handleNext}
      >
        <Svg type={SVG_TYPES.THIN_CHEVRON_RIGHT} />
      </div>
    </div>
  )
}

Pagination.propTypes = {
  meta: object.isRequired,
  fetchData: func.isRequired,
}
