import { useCallback, useState } from 'react'
import { MODAL_TYPES } from 'shared/consts'

const useActiveModal = (initialState = null) => {
  const [activeModalType, setVisible] = useState(initialState)

  // const toggle = useCallback(() => setVisible(prevValue => !prevValue), [])
  // const toggle = useCallback((value = null) => setVisible(value), [])
  const hideModal = useCallback(() => setVisible(null), [])
  const showAddModal = useCallback(() => setVisible(MODAL_TYPES.ADD), [])
  const showEditModal = useCallback(() => setVisible(MODAL_TYPES.EDIT), [])
  const showDeleteModal = useCallback(() => setVisible(MODAL_TYPES.DELETE), [])

  return { activeModalType, showAddModal, showEditModal, showDeleteModal, hideModal }
}

export default useActiveModal
