import { VIEW_MANAGEMENT } from 'store/types'

const setSelectedRow = data => ({
  type: VIEW_MANAGEMENT.SET_SELECTED_ROW,
  payload: {
    data,
  },
})

export default setSelectedRow
