import { LOCATION } from 'store/types'

const INITIAL_STATE = {
  locations: {
    data: undefined,
  },
  locationsOptions: {
    data: undefined,
  },
}

const locationoReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case LOCATION.GET_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: {
          data: payload.data,
        },
      }
    }
    case LOCATION.GET_LOCATIONS_OPTIONS_SUCCESS: {
      return {
        ...state,
        locationsOptions: {
          data: payload.data,
        },
      }
    }
    default:
      return state
  }
}

export default locationoReducer
