import axios from 'axios'
import store, { history } from 'store/store'
// import success from 'store/actions/auth/logout'
import { AUTH } from 'store/types'
import { ROUTES } from 'shared/consts'

const axiosPlugin = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

axiosPlugin.defaults.headers.post['Content-Type'] = 'application/json'

axiosPlugin.interceptors.request.use(
  config => {
    const copyConfig = config
    const { token } = store.getState().authStore

    if (token) {
      copyConfig.headers.Authorization = `Bearer ${token}`
    }

    return copyConfig
  },
  error => {
    Promise.reject(error)
  },
)

axiosPlugin.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    const originalRequest = error.config
    const { url } = originalRequest

    if (
      typeof error.response !== 'undefined' &&
      error.response.status === 401 &&
      url !== 'auth/login'
    ) {
      store.dispatch({ type: AUTH.LOGOUT_SUCCESS })
      history.push(ROUTES.LOGIN)
    }

    return Promise.reject(error)
  },
)

export const config = (params, headers) => {
  const configRq = {}
  if (params) {
    configRq.params = params
  }
  if (headers) {
    configRq.headers = headers
  }
  return configRq
}

export default axiosPlugin
