import { sessionConnector } from 'shared/connectors'
import { SESSION } from 'store/types'

const init = () => ({
  type: SESSION.GET_SINGLE_SESSION_INIT,
})
const success = data => ({
  type: SESSION.GET_SINGLE_SESSION_SUCCESS,
  payload: {
    data,
  },
})

const failure = () => ({
  type: SESSION.GET_SINGLE_SESSION_FAILURE,
})

const getSingleSession = uuid => async dispatch => {
  dispatch(init())
  try {
    const { data } = await sessionConnector.getSingleSession(uuid)
    dispatch(success(data))
  } catch (err) {
    dispatch(failure())
  }
}

export default getSingleSession
