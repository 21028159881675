import React from 'react'
import { bool, string, func } from 'prop-types'
import './Checkbox.scss'

function Checkbox({ checked, onChange, label }) {
  return (
    <label htmlFor={label} className="checkbox checkbox__label">
      <input type="checkbox" checked={checked} name={label} id={label} onChange={onChange} />
      {label}
    </label>
  )
}

Checkbox.propTypes = {
  checked: bool.isRequired,
  label: string.isRequired,
  onChange: func.isRequired,
}

export default Checkbox
