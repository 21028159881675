import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'shared/consts'
import { useSelector } from 'react-redux'
import styles from './DesktopNavigation.module.scss'

export const DesktopNavigation = () => {
  const { data } = useSelector(({ authStore }) => authStore.authorizedUser)
  return (
    <div className={styles.wrapper}>
      <Link to={ROUTES.ALL_SESSIONS}>Wszystkie nagrania</Link>
      {data && <Link to="/dashboard">Panel użytkownika</Link>}
      {/* <Link to={ROUTES.ALL_SESSIONS}>Zgłoś problem</Link> */}
    </div>
  )
}
