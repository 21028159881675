const VALIDATION_MESSAGES = {
  EMAIL: 'niepoprawny email',
  UNIQUE_EMAIL: 'podany email już istnieje',
  REQUIRED_FIELD: 'pole wymagane',
  WRONG_EMAIL: 'błędny email',
  WRONG_PASSWORD: 'błędne hasło',
  SAME_PASSWORD: 'hasła są niezgodne',
  REQUIRED_8_CHARS: 'wymagane minimum 8 znaków',
}

export default VALIDATION_MESSAGES
