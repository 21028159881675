import { SESSION } from 'store/types'
import { sessionConnector } from 'shared/connectors'
import {
  showPreloader,
  hidePreloader,
  showProgressBar,
  hideProgressBar,
} from 'store/actions/viewManagement'
import { getSelectedRow } from 'store/selectors/viewManagementSelector'
import { updateSelectedRow } from '../viewManagement'

const success = () => ({
  type: SESSION.ADD_SESSION_SUCCESS,
})

const addSession = sessionData => async (dispatch, getState) => {
  try {
    dispatch(showPreloader())
    const audioFile = new FormData()
    const cloneAudio = sessionData
    audioFile.append('recording', cloneAudio.audio)
    delete cloneAudio.audio

    const { error = false } = getSelectedRow(getState())
    const {
      data: { uuid },
    } = await sessionConnector.addSession(sessionData, error)

    dispatch(showProgressBar())
    await sessionConnector.addAudioToSession(audioFile, uuid)
    dispatch(hideProgressBar())

    dispatch(success())
    dispatch(hidePreloader())
    return true
  } catch ({ response: { data } }) {
    dispatch(updateSelectedRow(data))
    dispatch(hidePreloader())
    dispatch(hideProgressBar())
    return false
  }
}

export default addSession
