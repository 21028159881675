import { PREACHER } from 'store/types'

const INITIAL_STATE = {
  preachers: {
    data: undefined,
  },
  preachersOptions: {
    data: undefined,
  },
}

const preacherReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case PREACHER.GET_PREACHERS_SUCCESS: {
      return {
        ...state,
        preachers: {
          data: payload.data,
        },
      }
    }
    case PREACHER.GET_PREACHERS_OPTIONS_SUCCESS: {
      return {
        ...state,
        preachersOptions: {
          data: payload.data,
        },
      }
    }
    default:
      return state
  }
}

export default preacherReducer
