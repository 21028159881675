/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useTable, usePagination } from 'react-table'
import { func, array, bool, string, elementType } from 'prop-types'
import './Table.scss'
import ReactTooltip from 'react-tooltip'
import { Skeleton, Pagination } from 'shared/components'
import { RowPlaceholders } from './components/RowPlaceholders'
import { TableFilters } from './components/TableFilters'

function Table({
  columns,
  data,
  withPagination,
  withTooltip,
  fetchData,
  className,
  onRowClick,
  isLoading,
  EmptyStateComponent,
  filters,
  isSearchable,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, page, prepareRow } = useTable(
    {
      columns,
      data: withPagination ? data?.data || [] : data,
      initialState: {
        pageSize: 20,
        hiddenColumns: columns
          .filter(column => column.show !== undefined && !column.show)
          .map(column => column.accessor),
      },
      manualPagination: withPagination,
      pageCount: data?.meta?.last_page,
    },
    usePagination,
  )
  useEffect(() => {
    if (withTooltip) {
      ReactTooltip.rebuild()
    }
  }, [page])

  const dataToMap = withPagination ? page : rows

  return (
    <div className={`table ${className && `table__${className}`}`}>
      <ReactTooltip place="right" effect="solid" className="tooltip" />
      {filters.length > 0 && (
        <TableFilters filters={filters} fetchData={fetchData} isSearchable={isSearchable} />
      )}
      <table {...getTableProps()}>
        <thead>
          {isLoading ? (
            <tr className="placeholderRow">
              <th colSpan={20}>
                <Skeleton height={28} />
              </th>
            </tr>
          ) : dataToMap.length > 0 ? (
            headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))
          ) : null}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <RowPlaceholders />
          ) : (
            dataToMap.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} onClick={onRowClick && onRowClick(row.original)}>
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })
          )}
        </tbody>
      </table>
      {withPagination && data.meta?.total > 19 && !isLoading && (
        <Pagination meta={data.meta} fetchData={fetchData} />
      )}
      {!isLoading && data.meta?.total === 0 && <EmptyStateComponent />}
    </div>
  )
}

Table.defaultProps = {
  withPagination: false,
  withTooltip: false,
  isLoading: false,
  fetchData: undefined,
  className: '',
  onRowClick: undefined,
  EmptyStateComponent: () => null,
  filters: [],
  isSearchable: false,
}

Table.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
  withPagination: bool,
  withTooltip: bool,
  isLoading: bool,
  fetchData: func,
  className: string,
  onRowClick: func,
  EmptyStateComponent: elementType,
  filters: array,
  isSearchable: bool,
}

export default Table
