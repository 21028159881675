import React from 'react'
// eslint-disable-next-line import/order
// eslint-disable-next-line import/no-useless-path-segments
import { oneOfType, bool, number, string } from 'prop-types'
import { SVG_TYPES } from '../../consts'
import Svg from '../svg/Svg'
import './Status.scss'

function Status({ isVerified }) {
  return (
    <div data-testid="status" className={`status status--${isVerified ? 'success' : 'pending'}`}>
      <Svg type={isVerified ? SVG_TYPES.STATUS_SUCCESS : SVG_TYPES.STATUS_PENDING} />
    </div>
  )
}

Status.propTypes = {
  isVerified: oneOfType([bool, number, string]).isRequired,
}

export default Status
