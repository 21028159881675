import API from 'shared/connectors/config'

export function login(data) {
  return API.post('auth/login', data)
}

export function getAuthorizedUser() {
  return API.get('auth/me')
}

export function logout() {
  return API.delete('auth/logout')
}
