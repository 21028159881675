import React, { useCallback } from 'react'
import './Header.scss'
import { Svg } from 'shared/components'
import { useHistory } from 'react-router-dom'
import { SVG_TYPES } from 'shared/consts'
import { string } from 'prop-types'

function Header({ link, linkLabel, title }) {
  const { goBack, push } = useHistory()
  const handleBack = useCallback(() => (link ? push(link) : goBack()), [])

  return (
    <div className="header">
      <div className="header__link" onClick={handleBack}>
        <Svg type={SVG_TYPES.CHEVRON_DOWN} /> {linkLabel}
      </div>
      <div className="header__title">{title}</div>
    </div>
  )
}

Header.defaultProps = {
  title: '',
  link: '',
}

Header.propTypes = {
  linkLabel: string.isRequired,
  title: string,
  link: string,
}

export default Header
