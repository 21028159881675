import React, { useCallback } from 'react'
import { Table, Checkbox } from 'shared/components'
import { array, string, bool, func } from 'prop-types'

function PossibleDuplicates({ data, label, setIsChecked, isChecked, columns }) {
  const handleCheckboxChange = useCallback(() => setIsChecked(!isChecked), [isChecked])
  if (data) {
    return (
      <div className="modal__possible-duplicates">
        <h4>Wykryto możliwe duplikaty</h4>
        <Table.Table data={data} columns={columns} />
        <Checkbox label={label} checked={isChecked} onChange={handleCheckboxChange} />
      </div>
    )
  }
  return null
}

PossibleDuplicates.defaultProps = {
  data: null,
}

PossibleDuplicates.propTypes = {
  columns: array.isRequired,
  data: array,
  label: string.isRequired,
  isChecked: bool.isRequired,
  setIsChecked: func.isRequired,
}

export default PossibleDuplicates
