import React from 'react'
import './Navigation.scss'
import { bool, string } from 'prop-types'
import { MENU_ITEMS, PROTECTED_ROUTES } from 'shared/consts'
import { NavigationItem } from './components'

function Navigation({ authorizedUserRole, isExpanded }) {
  return (
    <div className="navigation">
      {MENU_ITEMS.map(({ label, path, icon }) => {
        if (PROTECTED_ROUTES.get(path)?.includes(authorizedUserRole)) {
          return (
            <NavigationItem
              key={label}
              isExpanded={isExpanded}
              label={label}
              path={path}
              icon={icon}
            />
          )
        }
        return null
      })}
    </div>
  )
}

Navigation.propTypes = {
  isExpanded: bool.isRequired,
  authorizedUserRole: string.isRequired,
}

export default Navigation
