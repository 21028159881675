import { CONVERSION_STATUSES, AUDIO_STATUSES } from 'shared/consts'

// const defaultValues = {
//   isAudioError: false,
//   isConversionPending: false,
//   isConversionRestartPossible: false,
//   isDuplicate: false,
// }

const conversionPendingStatuses = [
  CONVERSION_STATUSES.PENDING,
  CONVERSION_STATUSES.DOWNLOADING,
  CONVERSION_STATUSES.PROCESSING,
  CONVERSION_STATUSES.CONVERTING,
  CONVERSION_STATUSES.UPLOADING,
]

const getAudioStatus = (audio, isContentManager) => {
  // check if API returns audio field
  if (Array.isArray(audio)) {
    const isAudioError =
      !audio.length ||
      audio.some(
        ({ original_size, conversion_status }) =>
          original_size < 195000 ||
          conversion_status === CONVERSION_STATUSES.DOWNLOADING_FAILED ||
          conversion_status === CONVERSION_STATUSES.CONVERSION_FAILED,
      )
    if (isAudioError) return AUDIO_STATUSES.ERROR

    const isDuplicate =
      isContentManager &&
      audio.some(({ conversion_status }) => conversion_status === CONVERSION_STATUSES.DUPLICATE)
    if (isDuplicate) return AUDIO_STATUSES.DUPLICATE

    const isConversionPending =
      audio.length > 0 &&
      audio.some(({ conversion_status }) => conversionPendingStatuses.includes(conversion_status))
    if (isConversionPending) return AUDIO_STATUSES.CONVERSION_PENDING

    const isConversionRestartPossible =
      isContentManager &&
      !isAudioError &&
      audio.some(({ conversion_status }) => conversion_status.indexOf('_failed') > -1)
    if (isConversionRestartPossible) return AUDIO_STATUSES.CONVERSION_RESTART_POSSIBLE
  }
  return AUDIO_STATUSES.CORRECT
}

export default getAudioStatus
