import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Svg } from 'shared/components'
import { SVG_TYPES } from 'shared/consts'
import { Error } from '../components'
import './RegularField.scss'

const RegularField = ({
  type,
  field: { name, value },
  field,
  form,
  errors,
  placeholder,
  className,
  disabled,
  onChange,
  label,
  isClearable,
  onClear,
}) => {
  const isError = !!(form.touched[name] && errors[name])
  const fieldClasses = `regular-field__input ${isError ? 'regular-field__input--error' : ''} ${
    disabled ? 'regular-field__input--disabled' : ''
  } ${className}`

  const handleChange = useCallback(e => {
    field.onChange(e)
    if (onChange) {
      onChange()
    }
  }, [])

  const handleClear = useCallback(() => {
    form.setFieldValue(name, '')
    if (onClear) {
      onClear()
    }
  }, [form, onClear])

  return (
    <div className="regular-field">
      {label && <div className="regular-field__label">{label}</div>}
      <input
        autoComplete={name === 'password' ? 'on' : 'new-password'}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        value={value || ''}
        className={fieldClasses}
      />
      {isClearable && value && (
        <div
          className={`regular-field__clear ${label && 'regular-field__clear--aligned'}`}
          onClick={handleClear}
        >
          <Svg type={SVG_TYPES.EXIT} />
        </div>
      )}
      {isError && <Error fieldName={name} errors={errors} />}
    </div>
  )
}

RegularField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  label: PropTypes.string,
  isClearable: PropTypes.bool,
}

RegularField.defaultProps = {
  className: '',
  onChange: null,
  onClear: null,
  disabled: false,
  label: null,
  isClearable: false,
}

export default RegularField
