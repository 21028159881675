import { PREACHER } from 'store/types'
import { preacherConnector } from 'shared/connectors'
import { showPreloader, hidePreloader } from 'store/actions/viewManagement'

const success = () => ({
  type: PREACHER.DELETE_PREACHER_SUCCESS,
})

const deletePreacher = uuid => async dispatch => {
  try {
    dispatch(showPreloader())

    await preacherConnector.deletePreacher(uuid)

    dispatch(success())
    dispatch(hidePreloader())
  } catch (err) {
    dispatch(hidePreloader())
  }
}

export default deletePreacher
