import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FILTERS_PARAMS_ARRAY } from 'shared/consts'

export const useTableFilters = formRef => {
  const [initialValues, setInitialValues] = useState({})
  const { search, pathname } = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    if (FILTERS_PARAMS_ARRAY.some(param => queryParams.has(param))) {
      const queryParamsObject = Object.fromEntries(queryParams)
      if (queryParamsObject.status) {
        queryParamsObject.status = Number(queryParamsObject.status)
      }
      setInitialValues(queryParamsObject)
    }
  }, [])

  useEffect(() => {
    if (!search) {
      formRef.current.handleReset({})
      setInitialValues({})
    }
  }, [pathname])

  return { initialValues }
}
