import React from 'react'
import { connect } from 'react-redux'
import { getProgressBarData } from 'store/selectors/viewManagementSelector'
import { object } from 'prop-types'
import Circle from './components/Cricle'
import './ProgressBar.scss'

function ProgressBar({ progressBarData }) {
  return (
    <div className="progress-bar">
      <div className="progress-bar__title">Wysyłanie pliku</div>
      <div className="progress-bar__description">
        Trwa wysyłanie pliku audio do chmury <br /> poczekaj na ukończenie
      </div>
      <Circle
        percentage={progressBarData.percentage}
        remainingTime={progressBarData.remainingTime}
        uploadSpeed={progressBarData.uploadSpeed}
      />
    </div>
  )
}

ProgressBar.propTypes = {
  progressBarData: object.isRequired,
}

function mapStateToProps(state) {
  return {
    progressBarData: getProgressBarData(state),
  }
}

export default connect(mapStateToProps, null)(ProgressBar)
