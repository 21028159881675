import { authConnector } from 'shared/connectors'
import { AUTH } from 'store/types'

const success = data => ({
  type: AUTH.GET_AUTHORIZED_USER_SUCCESS,
  payload: {
    data,
  },
})

const failure = () => ({
  type: AUTH.GET_AUTHORIZED_USER_FAILURE,
})

const getAuthorizedUser = () => async dispatch => {
  try {
    const { data } = await authConnector.getAuthorizedUser()
    dispatch(success(data))
  } catch (err) {
    dispatch(failure())
  }
}

export default getAuthorizedUser
