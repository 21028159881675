const SESSION = {
  GET_SESSIONS_FAILURE: 'GET_SESSIONS_FAILURE',
  GET_SESSIONS_SUCCESS: 'GET_SESSIONS_SUCCESS',
  ADD_SESSION_SUCCESS: 'ADD_SESSION_SUCCESS',
  ADD_SESSION_FAILURE: 'ADD_SESSION_FAILURE',
  EDIT_SESSION_SUCCESS: 'EDIT_SESSION_SUCCESS',
  EDIT_SESSION_FAILURE: 'EDIT_SESSION_FAILURE',
  DELETE_SESSION_SUCCESS: 'DELETE_SESSION_SUCCESS',
  DELETE_SESSION_FAILURE: 'DELETE_SESSION_FAILURE',
  GET_LAST_ADDED_SESSIONS_FAILURE: 'GET_LAST_ADDED_SESSIONS_FAILURE',
  GET_LAST_ADDED_SESSIONS_SUCCESS: 'GET_LAST_ADDED_SESSIONS_SUCCESS',
  GET_SESSIONS_SEARCH_INIT: 'GET_SESSIONS_SEARCH_INIT',
  GET_SESSIONS_SEARCH_FAILURE: 'GET_SESSIONS_SEARCH_FAILURE',
  GET_SESSIONS_SEARCH_SUCCESS: 'GET_SESSIONS_SEARCH_SUCCESS',
  GET_SINGLE_SESSION_INIT: 'GET_SINGLE_SESSION_INIT',
  GET_SINGLE_SESSION_FAILURE: 'GET_SINGLE_SESSION_FAILURE',
  GET_SINGLE_SESSION_SUCCESS: 'GET_SINGLE_SESSION_SUCCESS',
  CLEAR_SINGLE_SESSION: 'CLEAR_SINGLE_SESSION',

  ADD_SESSION_HISTORY_SUCCESS: 'ADD_SESSION_HISTORY_SUCCESS',
  GET_SESSIONS_HISTORY_FAILURE: 'GET_SESSIONS_HISTORY_FAILURE',
  GET_SESSIONS_HISTORY_SUCCESS: 'GET_SESSIONS_HISTORY_SUCCESS',

  // EVENTS
  GET_EVENTS_OPTIONS_FAILURE: 'GET_EVENTS_OPTIONS_FAILURE',
  GET_EVENTS_OPTIONS_SUCCESS: 'GET_EVENTS_OPTIONS_SUCCESS',

  // CATEGORIES
  GET_CATEGORIES_OPTIONS_FAILURE: 'GET_CATEGORIES_OPTIONS_FAILURE',
  GET_CATEGORIES_OPTIONS_SUCCESS: 'GET_CATEGORIES_OPTIONS_SUCCESS',
}

export default SESSION
