import React, { useRef } from 'react'
import { Svg } from 'shared/components'
import { useRowModal, useOutsideClick } from 'shared/hooks'
import { SVG_TYPES, AUDIO_STATUSES } from 'shared/consts'
import { object, func, array } from 'prop-types'
import { getAudioStatus } from 'shared/utils'

function Dots({ row, items, setSelectedRow }) {
  const [activeRowModal, toggleActiveRowModal] = useRowModal()
  const ref = useRef(null)

  const isModalVisible = activeRowModal === row.uuid
  const isAudioError = getAudioStatus(row?.audio) === AUDIO_STATUSES.ERROR
  const tooltipLabel = isAudioError
    ? 'Błąd pliku audio. Otwórz edycję i postępuj według instrukcji'
    : ''

  useOutsideClick(ref, toggleActiveRowModal, isModalVisible)

  return (
    <div className="dots" ref={ref}>
      <div
        className="dots__icon"
        onClick={() => {
          toggleActiveRowModal(row.uuid)
          setSelectedRow(row)
        }}
        data-tip={tooltipLabel}
        data-place="left"
      >
        <Svg type={SVG_TYPES.DOTS} />
        {isAudioError && <Svg type={SVG_TYPES.EXCLAMATION_MARK} />}
      </div>
      {isModalVisible && <Modal toggleActiveRowModal={toggleActiveRowModal} items={items} />}
    </div>
  )
}

Dots.propTypes = {
  row: object.isRequired,
  setSelectedRow: func.isRequired,
  items: array.isRequired,
}

function Modal({ toggleActiveRowModal, items }) {
  return (
    <div className="dots__modal">
      <div className="dots__icon--modal" onClick={toggleActiveRowModal}>
        <Svg type={SVG_TYPES.DOTS} />
      </div>
      <div className="dots__item-wrapper">
        {items.map(({ label, onClick, className = '' }) => (
          <div key={label} className={`dots__item ${className}`} onClick={onClick}>
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}

Modal.propTypes = {
  toggleActiveRowModal: func.isRequired,
  items: array.isRequired,
}

export default Dots
