import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getLocalStorage } from 'shared/utils'
import { ROUTES } from 'shared/consts'

const AuthRoute = props => {
  const { component: Component, ...rest } = props
  return (
    <Route
      {...rest}
      render={routeProps =>
        getLocalStorage('isUserLogged') ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
            }}
          />
        )
      }
    />
  )
}

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default AuthRoute
