import API from 'shared/connectors/config'

export const getLocations = params => API.get('locations', { params })

export const getLocationsOptions = () => API.get('locations/options')

export const addLocation = (data, error) => API.post(`locations${error ? '?force=true' : ''}`, data)

export const editLocation = (data, error) =>
  API.put(`locations/${data.uuid}${error ? '?force=true' : ''}`, data)

export const deleteLocation = uuid => API.delete(`locations/${uuid}`)
