import React from 'react'
import { node } from 'prop-types'
import './Container.scss'

function Container({ children }) {
  return <div className="container">{children}</div>
}

Container.propTypes = {
  children: node.isRequired,
}

export default Container
