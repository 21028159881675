import React, { useRef, useState } from 'react'
import './Topbar.scss'
import { useOutsideClick } from 'shared/hooks'
import { Svg, Button } from 'shared/components'
import { useHistory } from 'react-router-dom'
import { SVG_TYPES, ROUTES, BUTTON_THEMES } from 'shared/consts'
import { bool, func, object } from 'prop-types'
import { Dropdown } from './components'

function Topbar({ authorizedUser, logout, isHomePage }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const ref = useRef(null)
  const { push } = useHistory()

  const handleOnClick = () => setIsDropdownVisible(prevState => !prevState)

  useOutsideClick(ref, () => setIsDropdownVisible(false), isDropdownVisible)

  return (
    <div className={`topbar ${isHomePage ? 'topbar--home-page' : ''}`}>
      {authorizedUser ? (
        <div ref={ref} className="topbar__box">
          <div className="topbar__box">
            <div className="topbar__full-name" onClick={handleOnClick}>
              {authorizedUser?.first_name} {authorizedUser?.last_name}
            </div>
            <div
              className={`topbar__icon ${isDropdownVisible ? 'topbar__icon--rotated' : ''}`}
              onClick={handleOnClick}
            >
              <Svg type={SVG_TYPES.CHEVRON_DOWN} />
            </div>
            <div className="topbar__initials" onClick={handleOnClick}>
              {authorizedUser?.first_name[0]}
              {authorizedUser?.last_name[0]}
            </div>
          </div>
          {isDropdownVisible && <Dropdown logout={logout} isHomePage={isHomePage} />}
        </div>
      ) : (
        <div className="topbar__buttons">
          <Button
            theme={BUTTON_THEMES.BORDERED}
            size="small"
            label="Zarejestruj się"
            onClick={() => push(ROUTES.REGISTER)}
          />
          <Button
            theme={BUTTON_THEMES.PRIMARY}
            label="Zaloguj się"
            size="small"
            onClick={() => push(ROUTES.LOGIN)}
          />
        </div>
      )}
    </div>
  )
}

Topbar.defaultProps = {
  authorizedUser: null,
  isHomePage: false,
}

Topbar.propTypes = {
  logout: func.isRequired,
  isHomePage: bool,
  authorizedUser: object,
}

export default Topbar
