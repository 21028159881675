import { useCallback } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

const useQueryParams = () => {
  const history = useHistory()

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const paramsObject = Object.fromEntries(params)

  const setParams = useCallback(
    (paramsToSet, options) => {
      paramsToSet.forEach(({ key, value }) => {
        params.append(key, value)
      })
      history.push({
        search: params.toString(),
        ...options,
      })
    },
    [params],
  )

  const editParams = useCallback(
    (paramsToSet, options) => {
      paramsToSet.forEach(({ key, value }) => {
        params.delete(key)
        if (value || value === 0) {
          params.append(key, value)
        }
      })
      history.push({
        search: params.toString(),
        ...options,
      })
    },
    [params],
  )

  const deleteParams = useCallback(
    (paramsToSet, options) => {
      paramsToSet.forEach(({ key }) => params.delete(key))

      history.push({
        search: params.toString(),
        ...options,
      })
    },
    [params],
  )

  return { params: paramsObject, setParams, editParams, deleteParams }
}

export default useQueryParams
