import React from 'react'
import { node, string } from 'prop-types'
import './Wrapper.scss'

function Wrapper({ children, title }) {
  return (
    <div className="wrapper">
      {title && <div className="wrapper__title">{title}</div>}
      {children}
    </div>
  )
}

Wrapper.defaultProps = {
  title: '',
}

Wrapper.propTypes = {
  children: node.isRequired,
  title: string,
}

export default Wrapper
