import { SESSION } from 'store/types'
import { sessionConnector } from 'shared/connectors'

const success = data => ({
  type: SESSION.ADD_SESSION_HISTORY_SUCCESS,
  payload: {
    data,
  },
})

const addSessionHistory = sessionData => async dispatch => {
  try {
    const { data } = await sessionConnector.addSessionHistory(sessionData)
    dispatch(success(data))
    return true
  } catch ({ response: { data } }) {
    return false
  }
}

export default addSessionHistory
