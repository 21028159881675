import { AUTH } from 'store/types'
import { setLocalStorage, removeLocalStorage } from 'shared/utils'
// import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  authorizedUser: {
    data: null,
  },
}

const authReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case AUTH.LOGIN_SUCCESS: {
      setLocalStorage('isUserLogged', true)
      return {
        ...state,
      }
    }
    case AUTH.GET_AUTHORIZED_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: {
          data: payload.data,
        },
      }
    }
    case AUTH.GET_AUTHORIZED_USER_FAILURE: {
      removeLocalStorage('isUserLogged')
      return {
        ...state,
        authorizedUser: {
          data: null,
        },
      }
    }
    case AUTH.LOGOUT_SUCCESS: {
      removeLocalStorage('isUserLogged')
      return {
        ...state,
        authorizedUser: {
          data: null,
        },
      }
    }
    default:
      return state
  }
}

export default authReducer
