import React from 'react'
import { string } from 'prop-types'
import { AUDIO_STATUSES } from 'shared/consts'
import { Play, Svg } from 'shared/components'
import { getIconType, getTooltipLabel } from './utils'
import './AudioStatus.scss'

function AudioStatus({ status, audioUuid }) {
  if (status === AUDIO_STATUSES.CORRECT) return <Play uuid={audioUuid} />
  return (
    <div className={`audio-status audio-status--${status}`} data-tip={getTooltipLabel(status)}>
      <Svg type={getIconType(status)} />
    </div>
  )
}

AudioStatus.propTypes = {
  audioUuid: string.isRequired,
  status: string.isRequired,
}

export default AudioStatus
