import { sessionConnector } from 'shared/connectors'
import { SESSION } from 'store/types'

const success = data => ({
  type: SESSION.GET_LAST_ADDED_SESSIONS_SUCCESS,
  payload: {
    data,
  },
})

const failure = () => ({
  type: SESSION.GET_LAST_ADDED_SESSIONS_FAILURE,
})

const getLastAddedSessions =
  (limit = 5) =>
  async dispatch => {
    try {
      const { data } = await sessionConnector.getLastAddedSessions(limit)
      dispatch(success(data))
    } catch (err) {
      dispatch(failure())
    }
  }

export default getLastAddedSessions
