import React from 'react'
import { string } from 'prop-types'

function GroupField({ label, value }) {
  return (
    <div className="modal__field-wrapper">
      <div className="modal__field-label">{label}</div>
      <div className="modal__input">{value}</div>
    </div>
  )
}

GroupField.defaultProps = {
  value: '',
}

GroupField.propTypes = {
  label: string.isRequired,
  value: string,
}

export default GroupField
