import { sessionConnector } from 'shared/connectors'
import { SESSION } from 'store/types'

const success = data => ({
  type: SESSION.GET_SESSIONS_HISTORY_SUCCESS,
  payload: {
    data,
  },
})

const failure = () => ({
  type: SESSION.GET_SESSIONS_HISTORY_FAILURE,
})

const getSessionsHistory = params => async dispatch => {
  try {
    const { data } = await sessionConnector.getSessionsHistory(params)
    dispatch(success(data))
  } catch (err) {
    dispatch(failure())
  }
}

export default getSessionsHistory
