import React from 'react'
import PropTypes from 'prop-types'
import './Error.scss'

function Error({ errors, fieldName }) {
  return <div className="error">{errors[fieldName]}</div>
}

Error.propTypes = {
  errors: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
}

export default Error
