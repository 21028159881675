import React from 'react'
import cx from 'classnames'
import { func, bool } from 'prop-types'
import styles from './Hamburger.module.scss'

export function Hamburger({ isExpanded, handleOnClick }) {
  return (
    <div className={styles.hamburger}>
      <div
        className={cx(styles.bars, {
          [styles.active]: isExpanded,
        })}
        onClick={handleOnClick}
      >
        <svg viewBox="0 0 64 48">
          <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37" />
          <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24" />
          <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37" />
        </svg>
      </div>
    </div>
  )
}

Hamburger.propTypes = {
  handleOnClick: func.isRequired,
  isExpanded: bool.isRequired,
}
