import { createSelector } from 'reselect'
import { USER_ROLES } from 'shared/consts'

const getAuthStore = state => state.authStore
const getAuthorizedUser = createSelector(getAuthStore, ({ authorizedUser: { data = {} } }) => data)
const getAuthorizedUserRole = createSelector(
  getAuthStore,
  ({ authorizedUser: { data: { role } = {} } }) => role,
)
const getIsContentManager = createSelector(
  getAuthStore,
  ({ authorizedUser: { data: { role } = {} } }) =>
    role === USER_ROLES.ADMIN || role === USER_ROLES.MODERATOR,
)

export { getAuthorizedUser, getAuthorizedUserRole, getIsContentManager }
