import React from 'react'
import 'styles/_globals.scss'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import store, { history } from 'store/store'
import Routes from './screens/Routes'

function RootApp() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route component={Routes} path="/" />
      </ConnectedRouter>
    </Provider>
  )
}

export default RootApp
