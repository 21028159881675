import USER_ROLES from './userRoles'
import USER_ROLES_LABELS from './userRolesLabels'

const ROLE_OPTIONS = [
  {
    label: USER_ROLES_LABELS[USER_ROLES.ADMIN],
    value: USER_ROLES.ADMIN,
  },
  {
    label: USER_ROLES_LABELS[USER_ROLES.MODERATOR],
    value: USER_ROLES.MODERATOR,
  },
  {
    label: USER_ROLES_LABELS[USER_ROLES.SUPER_UPLOADER],
    value: USER_ROLES.SUPER_UPLOADER,
  },
  {
    label: USER_ROLES_LABELS[USER_ROLES.UPLOADER],
    value: USER_ROLES.UPLOADER,
  },
  {
    label: USER_ROLES_LABELS[USER_ROLES.USER],
    value: USER_ROLES.USER,
  },
]

export default ROLE_OPTIONS
